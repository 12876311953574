import { API_URL } from "../../constants";
import { axiosInstance } from "../_base/axios.instance";

export async function getProfileImage({ userId, token }) {
  const response = await axiosInstance.get(`${API_URL}/users/profileImage/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob'
  });
  if (response) return response.data;
}
