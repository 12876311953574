import { createBrowserRouter } from "react-router-dom";
import * as uiScreens from "../ui/Screens";

export const router = createBrowserRouter([
  
  {
    path: "/",
    element: <uiScreens.Home/>,
  },
  {
    path: "/Login",
    element: <uiScreens.Login/>
  },
  
  {
    path: "/Contato",
    element: <uiScreens.Contact/>
  },
  {
    path: "/Busca",
    element: <uiScreens.Search/>
  },
  
  {
    path: "/Perfil",
    element: <uiScreens.Profile/>
  },
  {
    path: "/Post/:postId",
    element: <uiScreens.Post/>
  },
  {
    path: "/$$$$$ssdffaaabasqarqfffff",
    element: <uiScreens.Budget/>
  },
  {
    path: "/OrcamentoInfo/:budgetId",
    element: <uiScreens.BudgetInfo/>
  }
]);