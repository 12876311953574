import { API_URL } from "../../constants";
import { axiosInstance } from "../_base/axios.instance";

export async function alterPost({ postRequest, postId }) {
  const response = await axiosInstance.put(
    `${API_URL}/posts/alter/${postId}`,
    postRequest,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
}
