import { useHandleHover } from "../../../hook/index.jsx";
import "./slideCard.style.css";
import imageNotAvailable from "../../../assets/img/Image_not_available.png";
import { Link } from "react-router-dom";

export function SlideCard({ pic, title, postId }){

    const { hovered, handleMouseEnter, handleMouseLeave } = useHandleHover();
    function MainImageShow(){
        if(pic === null){
            return imageNotAvailable;
        }
        return `data:image/jpeg;base64,${pic}`;
    };

    return(
        <Link
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} 
        className="slidecard_link"
        to={`/Post/${postId}`}>
            <strong className={hovered ? "slidecard_strong show" : "slidecard_strong"}>{title}</strong>
            <img className="slidecard_img " src={MainImageShow()} alt="imagem do projeto" />
        </Link>
    );
};