import "./post.style.css";
import { Link, useParams } from "react-router-dom";
import "./post.screen";
import { useEffect, useState } from "react";
import { getPost } from "../../../api/post/getPost.api";
import { Footer, Header } from "../../Components";
import imageNotAvailable from "../../../assets/img/Image_not_available.png";
import arrowdown from "../../../assets/ico/arrowdown-ico.svg";

export function Post() {
  const { postId } = useParams();
  const [post, setPost] = useState({
    title: "",
    description: "",
    mainImage: "",
    city: "",
    country: "",
    state: "",
    batchArea: 0,
    builtArea: 0,
    collaborator: "",
    projectYear: 0,
    userId: 0,
    categories: [],
    images: [],
  });
  const [contentPosition, setContentPosition] = useState(0);
  const handleClick = (event) => {
    event.preventDefault();
    window.scrollTo({
      top: contentPosition,
      behavior: "smooth",
    });
  };
  async function fetchPost() {
    try {
      const response = await getPost({ postId: postId });
      setPost(response.data);
    } catch (error) {
      console.error(error);
    }
  }
  
  function ImageShow(image) {
    if (post.mainImage === null) {
      return imageNotAvailable;
    }
    return `data:image/jpeg;base64,${image}`;
  }

  useEffect(() => {
    const content = document.getElementById("postInfo_section");
    if (content) {
      setContentPosition(content.offsetTop);
    }
    fetchPost();
  }, []);
  return (
    <>
      <Header />
      <main className="post_main">
        <section className="post_mainImage--section">
          <img
            className="post_mainImage--img"
            src={ImageShow(post?.mainImage)}
            alt="Imagem de capa do post"
          />
          <strong className="post_mainImage--title">{post.title}</strong>
          <Link className="slide_arrowdown" onClick={handleClick}>
            <img
              src={arrowdown}
              alt="Flecha pra baixo para carregar mais posts"
            />
          </Link>
        </section>
        <section id="postInfo_section">
          <div className="postInfo_section--div">
            <div className="postInfo_div--title">
              <strong className="postInfo_div--strong">Ficha Técnica</strong>
              <span className="postInfo_div--span">Projeto arquitetônico</span>
            </div>
            <div className="postInfo_div--category">
              <div className="postInfo_category--info">
                <strong>Colaboração:</strong>
                <span>{post?.collaborator}</span>
              </div>
              <div className="postInfo_category--info">
                <strong>País:</strong>
                <span>{post?.country}</span>
              </div>
              <div className="postInfo_category--info">
                <strong>Área do lote:</strong>
                <span>{post?.batchArea}m²</span>
              </div>
              <div className="postInfo_category--info">
                <strong>Estado:</strong>
                <span>{post?.state}</span>
              </div>
              <div className="postInfo_category--info">
                <strong>Área construída:</strong>
                <span>{post?.builtArea}m²</span>
              </div>
              <div className="postInfo_category--info">
                <strong>City:</strong>
                <span>{post?.city}</span>
              </div>
              <div className="postInfo_category--info">
                <strong>Ano do projeto:</strong>
                <span>{post?.projectYear}</span>
              </div>
              {post?.categories.map((category, index) => {
                return (
                  <div key={index} className="postInfo_category--info">
                    <strong>{category.title}:</strong>
                    <span>{category.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="postInfo_section--div"></div>
        </section>
        <section className="postInfo_section--about">
          <strong className="postInfo_div--strong">Sobre</strong>
          <div className="postInfo_section--description">
            <p>{post?.description}</p>
          </div>
        </section>
        <section className="postInfo_section--media">
          {post?.images.map((image, index) => {
            return (
              <img
                className="postInfo--img"
                src={ImageShow(image.image)}
                alt="Imagem do post"
                key={index}
              />
            );
          })}
          {post?.videos?.map((video, index) => {
            const videoIdMatch = video.link.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
            const videoId = videoIdMatch ? videoIdMatch[1] : null;
            return (
              <iframe
                key={index}
                className="postInfo--video"
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            );
          })}
        </section>
        <section className="postInfo_section--videos"></section>
      </main>
      <Footer />
    </>
  );
}
