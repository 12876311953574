import { useNotify, usePage } from "../../../hook";
import { useEffect, useState } from "react";
import { getAllSlides } from "../../../api/slide/getAllSlides.api";
import { EditSlideOrderCard } from "../EditSlideOrderCard/editSlideOrderCard.component";
import "./editSlideOrder.style.css";
import { alterSlide } from "../../../api/slide/alterSlideOrder.api";

export function EditSlideOrder() {
  const { page, handleNextPage, handlePreviousPage } = usePage();
  const [slideSelected, setSlideSelected] = useState(undefined);
  const [slides, setSlides] = useState([]);
  const [hasNextPage, setNextPage] = useState(true);
  const { notify } = useNotify();

  function handleSubmit(event) {
    event.preventDefault();
  }

  async function fetchSlides() {
    try {
      const response = await getAllSlides({ page: page, SLIDEQUANTITY: 5 });
      setSlides(response.data.slides);
    } catch (e) {
      console.log(e);
    }
  }

  function handleOrder(slide) {
    setSlideSelected(slide);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const response = await alterSlide({
        slidedId: slideSelected.id,
        slideOrder: slideSelected.slideOrder,
      });
      notify(response.data.message);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchSlides();
  }, []);

  return (
    <>
      <form className="editSlideOrder_form" onSubmit={handleSubmit}>
        <strong>Selecione o slide a editar a ordem:</strong>
        <div className="editSlideOrder_form--slideDiv">
          {slides.map((slide, index) => (
            <EditSlideOrderCard
              handleOrder={handleOrder}
              slide={slide}
              key={index}
            />
          ))}
        </div>
        <div className="editSlideOrder_form--buttonDiv">
          <button
            disabled={!slideSelected}
            className="editSlideOrder_button--page"
          >
            Editar
          </button>
        </div>
      </form>
      <div className="editSlideOrder_div--button">
        <button
          className="editSlideOrder_button--page"
          onClick={handlePreviousPage}
        >
          Anterior
        </button>
        <span>{page}</span>
        <button
          className="editSlideOrder_button--page"
          onClick={hasNextPage ? handleNextPage : null}
        >
          Próximo
        </button>
      </div>
    </>
  );
}
