import { API_URL } from "../../constants";
import { axiosInstance } from "../_base/axios.instance";

export async function login({ email, password, stayConected}) {
    const response = await axiosInstance.post(
      `${API_URL}/auth/login`,
      {
        "email": email,
        "password": password,
        "stayConected": stayConected
      }
    );
    if(response) return response.data;
  };