import "./editSlideOrderCard.style.css";
import imageNotAvailable from "../../../assets/img/Image_not_available.png";
import { useState } from "react";

export function EditSlideOrderCard({ slide, handleOrder }) {
  function ImageShow(image) {
    if (slide.mainImage === null) {
      return imageNotAvailable;
    }
    return `data:image/jpeg;base64,${image}`;
  }

  function handleChange(event){
    slide.slideOrder = parseInt(event.target.value);
    handleOrder(slide);
  }

  return (
    <>
      <div className="editSlideOrderCard_card--div">
        <img
          className="editSlideOrderCard_img"
          src={ImageShow(slide.mainImage)}
          alt="Imagem do slide"
        />
        <div className="editSlideOrderCard_select--div">
          <span>Ordem do slide</span>
          <select onChange={handleChange} value={slide.slideOrder}>
            <option>{1}</option>
            <option>{2}</option>
            <option>{3}</option>
            <option>{4}</option>
            <option>{5}</option>
          </select>
        </div>
        <strong className="editSlideOrderCard_title--strong">
          {slide.postTitle}
        </strong>
      </div>
    </>
  );
}
