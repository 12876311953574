import { useEffect, useState } from "react";
import { useNotify, usePage } from "../../../hook";
import { getAllPosts } from "../../../api/post/getAllPosts.api";
import { RemovePostCard } from "../index";
import "./removePost.style.css";
import { removePost } from "../../../api/post/removePost.api";

export function RemovePost() {
  const { page, handlePreviousPage, handleNextPage } = usePage();
  const [posts, setPosts] = useState();
  const [postSelectedIndex, setPostSelectedIndex] = useState({
    post1: false,
    post2: false,
    post3: false,
    post4: false,
    post5: false,
    post6: false,
    post7: false,
    post8: false,
  });
  const [postSelected, setPostSelected] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { notify } = useNotify();

  function handleSelected(post, index, isselected) {
    if (isselected) {
      setPostSelected(post);
      setPostSelectedIndex((oldPostSelectedIndex) => {
        const resetState = {};
        Object.keys(oldPostSelectedIndex).forEach((key) => {
          resetState[key] = false;
        })
        return resetState;
      });
    } else {
      setPostSelectedIndex((oldPostSelectedIndex) => {
        const resetState = {};
        Object.keys(oldPostSelectedIndex).forEach((key) => {
          key === `post${index}` ?  resetState[key] = true : resetState[key] = false;
        })
        return resetState;
      });
      setPostSelected(post);
    }
  };

  async function fetchPosts() {
    try {
      const response = await getAllPosts({ page: page, POSTQUANTITY: 8 });
      setPosts(response.data.posts);
      setHasNextPage(response.data.hasNextPage);
    } catch (e) {
      console.log(e);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const response = await removePost({postId: postSelected.id});
      notify(response.data.detail);
      fetchPosts();
    } catch (e) {
      console.log(e.error);
    }
  }

  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form className="removePost_form" onSubmit={handleSubmit}>
        <strong>Selecione o post a remover:</strong>
        <div className="removePost_form--slideDiv">
          {posts?.map((post, index) => (
            <RemovePostCard
              postSelectedIndex={postSelectedIndex}
              handlePostSelected={handleSelected}
              key={index}
              index={index + 1}
              post={post}
              postSelected={postSelected}
            />
          ))}
        </div>
        <div className="removePost_form--buttonDiv">
          <button disabled={!postSelected} className="removePost_button--page">
            Remover
          </button>
        </div>
      </form>
      <div className="removePost_div--button">
        <button
          className="removePost_button--page"
          onClick={handlePreviousPage}
        >
          Anterior
        </button>
        <span>{page}</span>
        <button
          className="removePost_button--page"
          onClick={hasNextPage ? handleNextPage : null}
        >
          Próximo
        </button>
      </div>
    </>
  );
}
