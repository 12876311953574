import "./card.style.css";
import { useHandleHover } from "../../../hook/index.jsx";
import imageNotAvailable from "../../../assets/img/Image_not_available.png";
import { Link } from "react-router-dom";

export function Card({ post }) {

    const { hovered, handleMouseEnter, handleMouseLeave } = useHandleHover();
    function MainImageShow(){
        if(post.mainImage === null){
            return imageNotAvailable;
        }
        return `data:image/jpeg;base64,${post.mainImage}`;
    };

    return (
        <Link
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} 
        className="card_link"
        to={`/Post/${post.id}`}>
            <strong className={hovered ? "card_strong show" : "card_strong"}>{post.title}</strong>
            <img className="card_img " src={MainImageShow()} alt="imagem do projeto" />
        </Link>
    );
};