import { useState } from "react";
import "./addSlide.style.css";
import { useNotify, usePage } from "../../../hook";
import { useEffect } from "react";
import { getAllPosts } from "../../../api/post/getAllPosts.api";
import { SlidePostCard } from "../index";
import { addSlide } from "../../../api/slide/addSlide.api";

export function AddSlide() {
  const [posts, setPosts] = useState([]);
  const [postSelected, setPostSelected] = useState();
  const { page, handleNextPage, handlePreviousPage } = usePage();
  const [hasNextPage, setHasNextPage] = useState(false);
  const [postSelectedIndex, setPostSelectedIndex] = useState({
    post1: false,
    post2: false,
    post3: false,
    post4: false,
    post5: false,
    post6: false,
    post7: false,
    post8: false,
  });
  const { notify } = useNotify();

  async function fetchPosts() {
    try {
      const response = await getAllPosts({ page: page, POSTQUANTITY: 6 });
      setPosts(response.data.posts);
      setHasNextPage(response.data.hasNextPage);
    } catch (e) {
      console.log(e);
    }
  }

  async function handleSubmit(event) {

    event.preventDefault();
    try {
      const response = await addSlide({ postId: postSelected.id});
      notify(response.data.message);
    } catch (error) {
      notify(error.response.data.message);
    }
  }

  function handleSelected(post, index, isselected) {
    if (isselected) {
      setPostSelected(post);
      setPostSelectedIndex((oldPostSelectedIndex) => {
        const resetState = {};
        Object.keys(oldPostSelectedIndex).forEach((key) => {
          resetState[key] = false;
        })
        return resetState;
      });
    } else {
      setPostSelectedIndex((oldPostSelectedIndex) => {
        const resetState = {};
        Object.keys(oldPostSelectedIndex).forEach((key) => {
          key === `post${index}` ?  resetState[key] = true : resetState[key] = false;
        })
        return resetState;
      });
      setPostSelected(post);
    }
  };

  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <form className="addSlide_form" onSubmit={handleSubmit}>
        <strong>Selecione o post do slide:</strong>
        <div className="addSlide_form--postDiv">
          {posts.map((post, index) => (
            <SlidePostCard
              handlePostSelected={handleSelected}
              postSelectedIndex={postSelectedIndex}
              key={index}
              index={index+1}
              post={post}
            />
          ))}
        </div>
        <div className="addSlide_form--buttonDiv">
          <button disabled={!postSelected} className="addSlide_button--page">
            Adicionar
          </button>
        </div>
      </form>
      <div className="addSlide_div--button">
        <button className="addSlide_button--page" onClick={handlePreviousPage}>
          Anterior
        </button>
        <span>{page}</span>
        <button
          className="addSlide_button--page"
          onClick={hasNextPage ? handleNextPage : null}
        >
          Próximo
        </button>
      </div>
    </>
  );
}
