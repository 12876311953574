import "./slideModal.style.css";
import { Link } from "react-router-dom";
import closeButton from "../../../assets/ico/close_button.svg";
import { useState } from "react";
import { AddSlide, RemoveSlide, EditSlideOrder } from "../index";

export function SlideModal({ modalOpened, handleModalOpened }) {
  const [modalOption, setModalOption] = useState("Add");

  function changeModalOption(option) {
    setModalOption(option);
  }

  function showContent(text) {
    if (text === "Add") {
      return <AddSlide />;
    } else if (text === "Delete") {
      return <RemoveSlide/>;
    } else if (text === "EditOrder") {
      return <EditSlideOrder/>;
    }
  }

  return (
    <section
      className={
        modalOpened ? "slideModal_section opened" : "slideModal_section"
      }
    >
      <div className="slideModal_section--header">
        <Link onClick={handleModalOpened}>
          <img src={closeButton} alt="botão para fechar a modal" />
        </Link>
      </div>
      <div className="slideModal_section--container">
        <div className="modal_section--navbar">
          <Link
            className={modalOption === "Add" ? "selected" : null}
            onClick={() => changeModalOption("Add")}
          >
            Adicionar Slide
          </Link>
          <Link
            className={modalOption === "Delete" ? "selected" : null}
            onClick={() => changeModalOption("Delete")}
          >
            Deletar Slide
          </Link>
          <Link
            className={modalOption === "EditOrder" ? "selected" : null}
            onClick={() => changeModalOption("EditOrder")}
          >
            Alterar ordem dos slides
          </Link>
        </div>
        <section className="slideModal_content--section">
          {showContent(modalOption)}
        </section>
      </div>
    </section>
  );
}
