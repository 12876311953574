import { Card, Footer, Header, Slide } from "../../Components";
import "./home.style.css";
import { useState } from "react";
import { getAllPosts } from "../../../api/post/getAllPosts.api";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import arrowDown from "../../../assets/ico/arrow_down.svg";
import { usePage } from "../../../hook";
import { getAllSlides } from "../../../api/slide/getAllSlides.api";

export function Home() {
  const [posts, setPosts] = useState([]);
  const [slides, setSlides] = useState([]);
  const { page, handleNextPage } = usePage();
  const [hasNextPage, setHasNextPage] = useState(true);

  async function fetchPosts() {
    try {
      const response = await getAllPosts({ page: page, POSTQUANTITY: 8 });
      setPosts((oldPosts) => [...oldPosts, ...response.data.posts]);
      setHasNextPage(response.data.hasNextPage);
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchSlides() {
    try {
      const response = await getAllSlides({ page: 0, SLIDEQUANTITY: 5 });
      const sortedSlides = response.data.slides.sort(
        (a, b) => a.slideOrder - b.slideOrder
      );
      setSlides(sortedSlides);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchPosts();
  }, [page]);

  useEffect(() => {
    fetchSlides();
  }, []);

  return (
    <>
      <main className="home_main">
        <Slide slides={slides} />
        <Header selected="Home" />
        <section id="home_card-section">
          {posts.map((post, index) => {
            return <Card post={post} key={index} />;
          })}
        </section>
        <div className="arrowDown_div">
          <Link
            className="arrowDown_link"
            onClick={() => (hasNextPage ? handleNextPage() : null)}
          >
            <img
              src={arrowDown}
              alt="Flecha pra baixo para carregar mais posts"
            />
          </Link>
        </div>
      </main>
      <Footer />
    </>
  );
}
