import "./slidePostCard.style.css";
import imageNotAvailable from "../../../assets/img/Image_not_available.png";

export function SlidePostCard({ post, handlePostSelected, postSelectedIndex, index }) {

  function handleSelected() {
    if (postSelectedIndex[`post${index}`]) {
      handlePostSelected(undefined, index, true);
    } else {
      handlePostSelected(post, index, false);
    }
  }

  function ImageShow(image) {
    if (post.mainImage === null) {
      return imageNotAvailable;
    }
    return `data:image/jpeg;base64,${image}`;
  }

  return (
    <>
      <div
        onClick={handleSelected}
        className={
          postSelectedIndex[`post${index}`]
            ? "slidePostCard_card--div slideSelected"
            : "slidePostCard_card--div"
        }
      >
        <img
          className="slidePostCard_img"
          src={ImageShow(post.mainImage)}
          alt="Imagem do post"
        />
        <strong className="slidePostCard_title--strong">{post.title}</strong>
      </div>
    </>
  );
}
